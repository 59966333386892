/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { getEpsilon as t } from "./common.js";
function n(t, n) {
  return t[0] = n[0], t[1] = n[1], t[2] = n[2], t[3] = n[4], t[4] = n[5], t[5] = n[6], t[6] = n[8], t[7] = n[9], t[8] = n[10], t;
}
function a(t, n) {
  return t[0] = n[0], t[1] = n[1], t[2] = n[2], t[3] = n[3], t[4] = n[4], t[5] = n[5], t[6] = n[6], t[7] = n[7], t[8] = n[8], t;
}
function r(t, n, a, r, o, u, s, c, e, i) {
  return t[0] = n, t[1] = a, t[2] = r, t[3] = o, t[4] = u, t[5] = s, t[6] = c, t[7] = e, t[8] = i, t;
}
function o(t) {
  return t[0] = 1, t[1] = 0, t[2] = 0, t[3] = 0, t[4] = 1, t[5] = 0, t[6] = 0, t[7] = 0, t[8] = 1, t;
}
function u(t, n) {
  if (t === n) {
    const a = n[1],
      r = n[2],
      o = n[5];
    t[1] = n[3], t[2] = n[6], t[3] = a, t[5] = n[7], t[6] = r, t[7] = o;
  } else t[0] = n[0], t[1] = n[3], t[2] = n[6], t[3] = n[1], t[4] = n[4], t[5] = n[7], t[6] = n[2], t[7] = n[5], t[8] = n[8];
  return t;
}
function s(t, n) {
  const a = n[0],
    r = n[1],
    o = n[2],
    u = n[3],
    s = n[4],
    c = n[5],
    e = n[6],
    i = n[7],
    M = n[8],
    h = M * s - c * i,
    f = -M * u + c * e,
    b = i * u - s * e;
  let l = a * h + r * f + o * b;
  return l ? (l = 1 / l, t[0] = h * l, t[1] = (-M * r + o * i) * l, t[2] = (c * r - o * s) * l, t[3] = f * l, t[4] = (M * a - o * e) * l, t[5] = (-c * a + o * u) * l, t[6] = b * l, t[7] = (-i * a + r * e) * l, t[8] = (s * a - r * u) * l, t) : null;
}
function c(t, n) {
  const a = n[0],
    r = n[1],
    o = n[2],
    u = n[3],
    s = n[4],
    c = n[5],
    e = n[6],
    i = n[7],
    M = n[8];
  return t[0] = s * M - c * i, t[1] = o * i - r * M, t[2] = r * c - o * s, t[3] = c * e - u * M, t[4] = a * M - o * e, t[5] = o * u - a * c, t[6] = u * i - s * e, t[7] = r * e - a * i, t[8] = a * s - r * u, t;
}
function e(t) {
  const n = t[0],
    a = t[1],
    r = t[2],
    o = t[3],
    u = t[4],
    s = t[5],
    c = t[6],
    e = t[7],
    i = t[8];
  return n * (i * u - s * e) + a * (-i * o + s * c) + r * (e * o - u * c);
}
function i(t, n, a) {
  const r = n[0],
    o = n[1],
    u = n[2],
    s = n[3],
    c = n[4],
    e = n[5],
    i = n[6],
    M = n[7],
    h = n[8],
    f = a[0],
    b = a[1],
    l = a[2],
    m = a[3],
    d = a[4],
    x = a[5],
    p = a[6],
    y = a[7],
    j = a[8];
  return t[0] = f * r + b * s + l * i, t[1] = f * o + b * c + l * M, t[2] = f * u + b * e + l * h, t[3] = m * r + d * s + x * i, t[4] = m * o + d * c + x * M, t[5] = m * u + d * e + x * h, t[6] = p * r + y * s + j * i, t[7] = p * o + y * c + j * M, t[8] = p * u + y * e + j * h, t;
}
function M(t, n, a) {
  const r = n[0],
    o = n[1],
    u = n[2],
    s = n[3],
    c = n[4],
    e = n[5],
    i = n[6],
    M = n[7],
    h = n[8],
    f = a[0],
    b = a[1];
  return t[0] = r, t[1] = o, t[2] = u, t[3] = s, t[4] = c, t[5] = e, t[6] = f * r + b * s + i, t[7] = f * o + b * c + M, t[8] = f * u + b * e + h, t;
}
function h(t, n, a) {
  const r = n[0],
    o = n[1],
    u = n[2],
    s = n[3],
    c = n[4],
    e = n[5],
    i = n[6],
    M = n[7],
    h = n[8],
    f = Math.sin(a),
    b = Math.cos(a);
  return t[0] = b * r + f * s, t[1] = b * o + f * c, t[2] = b * u + f * e, t[3] = b * s - f * r, t[4] = b * c - f * o, t[5] = b * e - f * u, t[6] = i, t[7] = M, t[8] = h, t;
}
function f(t, n, a) {
  const r = a[0],
    o = a[1],
    u = a[2];
  return t[0] = r * n[0], t[1] = r * n[1], t[2] = r * n[2], t[3] = o * n[3], t[4] = o * n[4], t[5] = o * n[5], t[6] = u * n[6], t[7] = u * n[7], t[8] = u * n[8], t;
}
function b(t, n, a) {
  const r = a[0],
    o = a[1];
  return t[0] = r * n[0], t[1] = r * n[1], t[2] = r * n[2], t[3] = o * n[3], t[4] = o * n[4], t[5] = o * n[5], t;
}
function l(t, n) {
  return t[0] = 1, t[1] = 0, t[2] = 0, t[3] = 0, t[4] = 1, t[5] = 0, t[6] = n[0], t[7] = n[1], t[8] = 1, t;
}
function m(t, n) {
  const a = Math.sin(n),
    r = Math.cos(n);
  return t[0] = r, t[1] = a, t[2] = 0, t[3] = -a, t[4] = r, t[5] = 0, t[6] = 0, t[7] = 0, t[8] = 1, t;
}
function d(t, n) {
  return t[0] = n[0], t[1] = 0, t[2] = 0, t[3] = 0, t[4] = n[1], t[5] = 0, t[6] = 0, t[7] = 0, t[8] = 1, t;
}
function x(t, n) {
  return t[0] = n[0], t[1] = n[1], t[2] = 0, t[3] = n[2], t[4] = n[3], t[5] = 0, t[6] = n[4], t[7] = n[5], t[8] = 1, t;
}
function p(t, n) {
  const a = n[0],
    r = n[1],
    o = n[2],
    u = n[3],
    s = a + a,
    c = r + r,
    e = o + o,
    i = a * s,
    M = r * s,
    h = r * c,
    f = o * s,
    b = o * c,
    l = o * e,
    m = u * s,
    d = u * c,
    x = u * e;
  return t[0] = 1 - h - l, t[3] = M - x, t[6] = f + d, t[1] = M + x, t[4] = 1 - i - l, t[7] = b - m, t[2] = f - d, t[5] = b + m, t[8] = 1 - i - h, t;
}
function y(t, n) {
  const a = n[0],
    r = n[1],
    o = n[2],
    u = n[4],
    s = n[5],
    c = n[6],
    e = n[8],
    i = n[9],
    M = n[10],
    h = M * s - c * i,
    f = -M * u + c * e,
    b = i * u - s * e,
    l = a * h + r * f + o * b;
  if (!l) return null;
  const m = 1 / l;
  return t[0] = h * m, t[1] = (-M * r + o * i) * m, t[2] = (c * r - o * s) * m, t[3] = f * m, t[4] = (M * a - o * e) * m, t[5] = (-c * a + o * u) * m, t[6] = b * m, t[7] = (-i * a + r * e) * m, t[8] = (s * a - r * u) * m, t;
}
function j(t, n) {
  const a = n[0],
    r = n[1],
    o = n[2],
    u = n[3],
    s = n[4],
    c = n[5],
    e = n[6],
    i = n[7],
    M = n[8],
    h = n[9],
    f = n[10],
    b = n[11],
    l = n[12],
    m = n[13],
    d = n[14],
    x = n[15],
    p = a * c - r * s,
    y = a * e - o * s,
    j = a * i - u * s,
    S = r * e - o * c,
    g = r * i - u * c,
    _ = o * i - u * e,
    q = M * m - h * l,
    O = M * d - f * l,
    v = M * x - b * l,
    A = h * d - f * m,
    F = h * x - b * m,
    T = f * x - b * d;
  let z = p * T - y * F + j * A + S * v - g * O + _ * q;
  return z ? (z = 1 / z, t[0] = (c * T - e * F + i * A) * z, t[1] = (e * v - s * T - i * O) * z, t[2] = (s * F - c * v + i * q) * z, t[3] = (o * F - r * T - u * A) * z, t[4] = (a * T - o * v + u * O) * z, t[5] = (r * v - a * F - u * q) * z, t[6] = (m * _ - d * g + x * S) * z, t[7] = (d * j - l * _ - x * y) * z, t[8] = (l * g - m * j + x * p) * z, t) : null;
}
function S(t, n, a) {
  return t[0] = 2 / n, t[1] = 0, t[2] = 0, t[3] = 0, t[4] = -2 / a, t[5] = 0, t[6] = -1, t[7] = 1, t[8] = 1, t;
}
function g(t) {
  return "mat3(" + t[0] + ", " + t[1] + ", " + t[2] + ", " + t[3] + ", " + t[4] + ", " + t[5] + ", " + t[6] + ", " + t[7] + ", " + t[8] + ")";
}
function _(t) {
  return Math.sqrt(t[0] ** 2 + t[1] ** 2 + t[2] ** 2 + t[3] ** 2 + t[4] ** 2 + t[5] ** 2 + t[6] ** 2 + t[7] ** 2 + t[8] ** 2);
}
function q(t, n, a) {
  return t[0] = n[0] + a[0], t[1] = n[1] + a[1], t[2] = n[2] + a[2], t[3] = n[3] + a[3], t[4] = n[4] + a[4], t[5] = n[5] + a[5], t[6] = n[6] + a[6], t[7] = n[7] + a[7], t[8] = n[8] + a[8], t;
}
function O(t, n, a) {
  return t[0] = n[0] - a[0], t[1] = n[1] - a[1], t[2] = n[2] - a[2], t[3] = n[3] - a[3], t[4] = n[4] - a[4], t[5] = n[5] - a[5], t[6] = n[6] - a[6], t[7] = n[7] - a[7], t[8] = n[8] - a[8], t;
}
function v(t, n, a) {
  return t[0] = n[0] * a, t[1] = n[1] * a, t[2] = n[2] * a, t[3] = n[3] * a, t[4] = n[4] * a, t[5] = n[5] * a, t[6] = n[6] * a, t[7] = n[7] * a, t[8] = n[8] * a, t;
}
function A(t, n, a, r) {
  return t[0] = n[0] + a[0] * r, t[1] = n[1] + a[1] * r, t[2] = n[2] + a[2] * r, t[3] = n[3] + a[3] * r, t[4] = n[4] + a[4] * r, t[5] = n[5] + a[5] * r, t[6] = n[6] + a[6] * r, t[7] = n[7] + a[7] * r, t[8] = n[8] + a[8] * r, t;
}
function F(t, n) {
  return t[0] === n[0] && t[1] === n[1] && t[2] === n[2] && t[3] === n[3] && t[4] === n[4] && t[5] === n[5] && t[6] === n[6] && t[7] === n[7] && t[8] === n[8];
}
function T(n, a) {
  const r = n[0],
    o = n[1],
    u = n[2],
    s = n[3],
    c = n[4],
    e = n[5],
    i = n[6],
    M = n[7],
    h = n[8],
    f = a[0],
    b = a[1],
    l = a[2],
    m = a[3],
    d = a[4],
    x = a[5],
    p = a[6],
    y = a[7],
    j = a[8],
    S = t();
  return Math.abs(r - f) <= S * Math.max(1, Math.abs(r), Math.abs(f)) && Math.abs(o - b) <= S * Math.max(1, Math.abs(o), Math.abs(b)) && Math.abs(u - l) <= S * Math.max(1, Math.abs(u), Math.abs(l)) && Math.abs(s - m) <= S * Math.max(1, Math.abs(s), Math.abs(m)) && Math.abs(c - d) <= S * Math.max(1, Math.abs(c), Math.abs(d)) && Math.abs(e - x) <= S * Math.max(1, Math.abs(e), Math.abs(x)) && Math.abs(i - p) <= S * Math.max(1, Math.abs(i), Math.abs(p)) && Math.abs(M - y) <= S * Math.max(1, Math.abs(M), Math.abs(y)) && Math.abs(h - j) <= S * Math.max(1, Math.abs(h), Math.abs(j));
}
function z(n) {
  const a = t(),
    r = n[0],
    o = n[1],
    u = n[2],
    s = n[3],
    c = n[4],
    e = n[5],
    i = n[6],
    M = n[7],
    h = n[8];
  return Math.abs(1 - (r * r + s * s + i * i)) <= a && Math.abs(1 - (o * o + c * c + M * M)) <= a && Math.abs(1 - (u * u + e * e + h * h)) <= a;
}
const B = i,
  E = O,
  L = Object.freeze(Object.defineProperty({
    __proto__: null,
    add: q,
    adjoint: c,
    copy: a,
    determinant: e,
    equals: T,
    exactEquals: F,
    frob: _,
    fromMat2d: x,
    fromMat4: n,
    fromQuat: p,
    fromRotation: m,
    fromScaling: d,
    fromTranslation: l,
    identity: o,
    invert: s,
    isOrthoNormal: z,
    mul: B,
    multiply: i,
    multiplyScalar: v,
    multiplyScalarAndAdd: A,
    normalFromMat4: j,
    normalFromMat4Legacy: y,
    projection: S,
    rotate: h,
    scale: f,
    scaleByVec2: b,
    set: r,
    str: g,
    sub: E,
    subtract: O,
    translate: M,
    transpose: u
  }, Symbol.toStringTag, {
    value: "Module"
  }));
export { q as add, c as adjoint, a as copy, e as determinant, T as equals, F as exactEquals, _ as frob, x as fromMat2d, n as fromMat4, p as fromQuat, m as fromRotation, d as fromScaling, l as fromTranslation, o as identity, s as invert, z as isOrthoNormal, L as m, B as mul, i as multiply, v as multiplyScalar, A as multiplyScalarAndAdd, j as normalFromMat4, y as normalFromMat4Legacy, S as projection, h as rotate, f as scale, b as scaleByVec2, r as set, g as str, E as sub, O as subtract, M as translate, u as transpose };